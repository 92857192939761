import type { Review } from '@typings/api/reviews';
import type { Gallery } from '@typings/reviews';
import { atom } from 'jotai';
import type {
	GoogleReviewsResponseData,
	ReviewAiSummaryResponseData,
} from 'types/api/reviews';

export interface ReviewStarBuckets {
	stars1: number;
	stars2: number;
	stars3: number;
	stars4: number;
	stars5: number;
}

export const totalCountAtom = atom<number>(0);
export const ratingAtom = atom<number>(0);
export const reviewStarBucketsAtom = atom<ReviewStarBuckets>({
	stars1: 0,
	stars2: 0,
	stars3: 0,
	stars4: 0,
	stars5: 0,
});
export const galleryAtom = atom<Gallery[]>([]);
export const isFetchingAtom = atom<boolean>(false);
export const ssrReviewsAtom = atom<Review[]>([]);
export const reviewAiSummaryAtom = atom<
	ReviewAiSummaryResponseData | undefined
>({});
export const googleReviewsAtom = atom<GoogleReviewsResponseData>({
	rating: 0,
	totalReviewCount: 0,
	externalUrl: '',
	reviews: [],
});
